<script lang="ts">
    let classNames = ''
    export { classNames as class }
    export let href = ''
    export let active = false
</script>

{#if href}
    <a
        class="app-card {active ? 'active' : ''} {classNames}"
        {href}
        on:click
        {...$$restProps}
    >
        <div class="app-card-image">
            <slot name="image" />
        </div>

        <div class="app-card-content">
            <slot name="content" />
        </div>
    </a>
{:else}
    <div class="app-card {classNames}" {...$$restProps}>
        <div class="app-card-image">
            <slot name="image" />
        </div>

        <div class="app-card-content">
            <slot name="content" />
        </div>
    </div>
{/if}

<style global lang="postcss">
    .app-card {
        display: flex;
        align-items: stretch;
        justify-content: flex-start;
        border: theme('borderWidth.DEFAULT') solid theme('colors.gray-200');
        border-radius: theme('borderRadius.DEFAULT');
    }

    .app-card-image img {
        max-width: 96px;
    }

    .app-card-content {
        width: 100%;
        padding: theme('spacing.4');
        overflow: hidden;
    }

    /* stylelint-disable-next-line */
    a.app-card:hover {
        border-color: theme('colors.green-500');
        background-color: theme('colors.white');
    }

    .app-card.active {
        border-color: theme('colors.green-800');
        background-color: theme('colors.white');
    }
</style>
